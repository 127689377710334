import NiceSelect from "nice-select2";
import { tns } from 'tiny-slider/dist/tiny-slider';

const toggleFormVisibility = (showRims) => {
    document.getElementById('formRims').classList.toggle('hidden', !showRims);
    document.getElementById('formTire').classList.toggle('hidden', showRims);
    document.getElementById('rimsForm').classList.toggle('active', showRims);
    document.getElementById('tiresForm').classList.toggle('active', !showRims);
};

document.getElementById('rimsForm').addEventListener('click', () => toggleFormVisibility(true));
document.getElementById('tiresForm').addEventListener('click', () => toggleFormVisibility(false));

// Funkcja inicjująca NiceSelect
const initNiceSelect = (select) => {
    const options = {
        searchable: false,
        placeholder: 'Wybierz',
        searchtext: 'Szukaj',
        selectedtext: ' wybrano'
    };
    new NiceSelect(select, options);
};

// Usuwanie focusu z selecta
document.addEventListener("DOMContentLoaded", function () {
    document.body.addEventListener("click", function (event) {
        const dropdown = event.target.closest(".nice-select");

        if (dropdown) {
            const searchInput = dropdown.querySelector(".nice-select-search");
            if (searchInput) {
                searchInput.blur(); // Usuwa focus z inputa
            }
        }
    });
});

// Kliknięcie wewnątrz




// Funkcja do aktualizacji opcji selecta
const updateSelectOptions = (selectElement, options, labelKey = 'width') => {
    selectElement.innerHTML = '<option value="">Wybierz</option>'; // Dodanie placeholdera
    options.forEach(option => {
        const optionElement = document.createElement('option');
        optionElement.value = option.id || option[labelKey];
        optionElement.textContent = option[labelKey];
        selectElement.appendChild(optionElement);
    });
    initNiceSelect(selectElement); // Inicjalizacja NiceSelect po dodaniu opcji
};

// Funkcja do załadowania danych z JSON do odpowiednich selectów
const loadTireAndRimData = async () => {
    try {
        // Pobieranie danych dla opon
        const tireResponse = await fetch('https://opony.strefakierowcy.pl/tires.json');
        const tireData = await tireResponse.json();
        console.log('Tire Data:', tireData); // Log danych opon

        // Pobieranie danych dla felg
        const rimResponse = await fetch('https://opony.strefakierowcy.pl/rims.json');
        const rimData = await rimResponse.json();
        console.log('Rim Data:', rimData); // Log danych felg

        const tireSelectElements = {
            tireWidth: document.getElementById('tireWidth'),
            tireProfile: document.getElementById('tireProfile'),
            rimDiameter: document.getElementById('rimDiameter'),
            tireSeason: document.getElementById('tireSeason') // jeżeli istnieje
        };

        const rimSelectElements = {
            rimSize: document.getElementById('rimSize'),
            rimDiameters: document.getElementById('rimDiameters'),
            rimSpacing1: document.getElementById('rimScrew'), // jeżeli istnieje
            rimSpacing2: document.getElementById('rimSpacing') // jeżeli istnieje
        };

        // Aktualizacja selectów dla opon
        if (tireSelectElements.tireWidth && tireData.tireWidth) {
            updateSelectOptions(tireSelectElements.tireWidth, tireData.tireWidth, 'width');
        }
        if (tireSelectElements.tireProfile && tireData.tireProfile) {
            updateSelectOptions(tireSelectElements.tireProfile, tireData.tireProfile, 'width');
        }
        if (tireSelectElements.rimDiameter && tireData.rimDiameter) {
            updateSelectOptions(tireSelectElements.rimDiameter, tireData.rimDiameter, 'width');
        }
        if (tireSelectElements.tireSeason) {
            initNiceSelect(tireSelectElements.tireSeason); // Domyślna inicjalizacja
        }

        // Aktualizacja selectów dla felg
        if (rimSelectElements.rimSize && rimData.rimSize) {
            updateSelectOptions(rimSelectElements.rimSize, rimData.rimSize, 'width');
        }
        if (rimSelectElements.rimDiameters && rimData.rimDiameter) {
            updateSelectOptions(rimSelectElements.rimDiameters, rimData.rimDiameter, 'width');
        }
        if (rimSelectElements.rimSpacing1 && rimData.rimSpacing1) {
            updateSelectOptions(rimSelectElements.rimSpacing1, rimData.rimSpacing1, 'width');
        }
        if (rimSelectElements.rimSpacing2 && rimData.rimSpacing2) {
            updateSelectOptions(rimSelectElements.rimSpacing2, rimData.rimSpacing2, 'width');
        }

    } catch (error) {
        console.error('Błąd podczas ładowania danych z JSON:', error);
    }
};

// Ładowanie danych po załadowaniu DOM
document.addEventListener('DOMContentLoaded', () => {
    loadTireAndRimData(); // Załaduj dane z JSON dla opon i felg
});



document.querySelectorAll('.products').forEach(section => {
    const carouselContainer = section.querySelector('.products__carousel');
    const navContainer = section.querySelector('.products__nav');

    tns({
        container: carouselContainer,
        slideBy: 1,
        autoplay: false,
        loop: true,
        mouseDrag: true,
        nav: false,
        controlsContainer: navContainer,
        items: 1.15,
        gutter: 8,
        center: false,
        responsive: {
            570: {
                items: 2.15,
                gutter: 16,
            },
            768: {
                items: 1.1,
                gutter: 24,
                edgePadding: 0,
            },
            1280: {
                gutter: 32,
                items: 2.1,
                edgePadding: 40,
                loop: true,
            }
        }
    });
});

document.addEventListener("DOMContentLoaded", () => {
    const removeWidow = (text) => {
        let words = text.split(' ');
        if (words.length <= 1) {
            return text;
        }
        let allButLastWord = words.slice(0, -1).join(' ');
        let lastWord = words[words.length - 1];
        return `${allButLastWord}\u00A0${lastWord}`;
    };

    document.querySelectorAll('.story__content p').forEach(element => {
        element.innerHTML = removeWidow(element.innerHTML);
    });
});

document.getElementById('story__content-button').addEventListener('click', (event) => {
    event.preventDefault();

    let content = document.querySelector('.story__content');
    let button = event.currentTarget;

    if (content.classList.contains('expanded')) {
        content.classList.remove('expanded');
        button.innerHTML = 'Rozwiń opis <span>+</span>';
    } else {
        content.classList.add('expanded');
        button.innerHTML = 'Zwiń opis <span>-</span>';
    }
});

